$text-color: #FAF9F9;
$background-color: #353866;
$slide-color: #80FFE8;
$grey-color: #504b46;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;

@mixin smooth-transition {
  -webkit-transition: all 0.25s; /* Safari and Chrome */
  -moz-transition: all 0.25s; /* Firefox */
  -ms-transition: all 0.25s; /* IE 9 */
  -o-transition: all 0.25s; /* Opera */
  transition: all 0.25s;
}
