@import "variables";

// General

html, body {
	height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $background-color;
}

* {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
}

// CSS for ID

#about {
	width: 75vw;
	max-width: 600px;
	padding-top: 50px 100px;
	padding-bottom: 25px;
	font-size: 14px;
  text-align: center;
}

#Back {
	display: none;
	height: 100%;
	width: 100%;
	max-height: 50px;
	max-width: 50px;
	min-height: 24px;
	min-width: 24px;
	padding-top: 50px 100px;
	margin: 0 auto;
	z-index: 1001;
	-webkit-transition: all .2s;
	&:focus,
	&:hover {
		cursor: pointer;
		opacity: 0.75;
	}
}

#joke-container {
	@include smooth-transition;
	width: 75vw;
	max-width: 600px;
}

#joke-title {
	padding-bottom: 20px;
	margin: 50px auto 0;
	font-size: 10vw;
}

#joke-text {
	white-space: pre-wrap;
	font-size: 4vw;
	text-align: left;
}

#Permalink {
	display: block;
	height: 100%;
	width: 100%;
	max-height: 50px;
	max-width: 50px;
	min-height: 24px;
	min-width: 24px;
	padding-top: 50px 100px;
	margin: 0 auto;
	z-index: 1001;
	-webkit-transition: all .2s;
	&:focus,
	&:hover {
		cursor: pointer;
		opacity: 0.75;
	}
}

#PermalinkWindow {
	display: none;
	position: fixed;
	padding-bottom: 20px;
	width: 30%;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
	z-index: 1002;
	text-align: center;
	border-radius: 10px;
  background-color: $grey-color;
	opacity: 0;
}

#Reload {
	height: 100%;
	width: 100%;
	max-height: 50px;
	max-width: 50px;
	min-height: 24px;
	min-width: 24px;
	padding-top: 50px 100px;
	margin: 0 auto;
	z-index: 1001;
	-webkit-transition: all .2s;
	&:focus,
	&:hover {
		cursor: pointer;
		opacity: 0.75;
	}
}

#Splash {
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
  top: 0;
  left: 0;
	z-index: 1002;
  background-color: $background-color;
}

#splashText {
	position: relative;
	white-space: pre-wrap;
	top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
	margin-top: 10px;
	font-size: 4vh;
	opacity: 0;
}

// CSS for Class
.btd {
	width: 5vw;
	align-items: center;
}

.close {
    height: 5%;
		width: 5%;
		max-height: 50px;
		max-width: 50px;
		min-height: 24px;
		min-width: 24px;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 2;
    -webkit-transition: all .2s;
		opacity: 1;
}

.content {
	display: flex;
  align-items: center;
  flex-direction: column;
	width: 80%;
  margin: 0 auto;
}

.gear {
		position: fixed;
		height: 5%;
		width: 5%;
		max-height: 50px;
		max-width: 50px;
		min-height: 24px;
		min-width: 24px;
    top: 25px;
    right: 25px;
		-webkit-transition: all .2s;
		&:focus,
		&:hover {
			cursor: pointer;
			opacity: 0.75;
		}
}

.link {
	color: $slide-color;
	font-size: 14px;
	z-index: 1001;
}

.settings-title {
  font-family: Domaine Display;
  margin-top: 0;
  margin-bottom: 10px;
	font-size: 32px;
	text-align: left;
	margin-left: 25px;
}

.settings-table {
	margin: 0 auto;
}

.settings-line {
	padding: 10px;
}

.overlay {
	display: none;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.5;
}

.permalink {
	padding: 20px;
	color: $slide-color;
	font-size: 2vw;
	z-index: 1001;
}

.permalink-title {
  font-family: Domaine Display;
  margin-top: 5%;
  margin-bottom: 10px;
	font-size: 3vw;
	text-align: center;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: $grey-color; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $text-color;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: $grey-color;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $slide-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $slide-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.splashIcon {
	position: relative;
	max-width: 15vh;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
	opacity: 0;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
	vertical-align: center;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tdr {
	text-align: right;
}

.toggle-label {
  font-family: National;
	font-size: 20px;
	color: $text-color;
	margin-right: 10px;
}

// Screen Size Dependant

@media screen and (min-height: 500px) {
	.btd {
		width: 100px;
	}
}

@media screen and (min-width: 500px) {
  #joke-title {
     font-size: 50px;
  }
	#joke-text {
		font-size: 30px;
	}
}

@media (min-width: $md) {
	width: 60%;
}
